import { graphql } from "gatsby"
import ProjectComponent from "../@lekoarts/gatsby-theme-minimal-blog/components/project"

export default ProjectComponent

export const query = graphql`
  query($slug: String!) {
    project(slug: { eq: $slug }) {
      title
      slug
      icon
      excerpt
      body
    }
  }
`
